import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Lifecycle Management",
  "description": "Lifecycle Management is done when you're already in the mature stage of your product development",
  "author": "",
  "categories": ["onboarding"],
  "date": null,
  "featured": false,
  "tags": ["onboarding"],
  "relatedArticles": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Once you launch a product, its lifecycle has started. It’ll start from the `}<strong parentName="p">{`introduction stage.`}</strong>{` Afterward, it’ll `}<strong parentName="p">{`grow`}</strong>{`, increase in popularity, and reach, and keeps on maturing until it’s fully developed. (read more about Product Lifecycle)`}</p>
    <p>{`At this stage, `}<strong parentName="p">{`retaining`}</strong>{` the market share in the industry is essential. This requires advanced problem-solving skills. Finally, if your product had finally `}<strong parentName="p">{`declined`}</strong>{` in the market, maybe due to a huge problem, or its outdated technology, solutions such as business pivoting or production halt should be considered.`}</p>
    <p>{`NexPCB is as committed to your product as you are. We will assist you to take active and careful steps throughout the product lifecycle stages to give an optimum outcome.`}</p>
    <p>{`NexPCB always hopes that we can grow together as partners. Other than establishing and launching new projects`}<strong parentName="p">{`,`}</strong>{` we also hope older projects can grow to maturity. This means the `}<strong parentName="p">{`repairability, maintenance,`}</strong>{` and `}<strong parentName="p">{`improvement`}</strong>{` of the projects will play a major role.`}</p>
    <p>{`To do so, NexPCB is well-equipped with our `}<strong parentName="p">{`logistics, supply-chain management,`}</strong>{` and `}<strong parentName="p">{`quality control`}</strong>{` capabilities.`}</p>
    <p>{`NexPCB will help find essential components and materials through its large chain of suppliers.`}</p>
    <p>{`NexPCB is also certified to follow the ISO 9001 Quality Management Standard.`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": ""
        }}><strong parentName="a">{`Lets start working on your project`}</strong></a></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      